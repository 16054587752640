import { useState } from "react";
import { PLAYBACK_SPEED_OPTIONS } from "common/constants";
import "components/playbackSpeedControl/_playbackSpeedControl.scss";

export default function PlaybackSpeedControl({ audioPlayer }) {
  const [playbackRate, setPlaybackRate] = useState(1);

  const handleSpeedChange = (rate) => {
    setPlaybackRate(rate);
    if (audioPlayer.current) {
      audioPlayer.current.audio.current.playbackRate = rate;
    }
  };

  return (
    <div className="playback-speed-control">
      <select 
        onChange={(e) => handleSpeedChange(parseFloat(e.target.value))} 
        value={playbackRate} 
        className="playback-speed-dropdown"
      >
        {PLAYBACK_SPEED_OPTIONS.map((rate) => (
          <option key={rate} value={rate}>
            {rate}x
          </option>
        ))}
      </select>

    </div>
  );
}
