import React, { useEffect, useRef, useState } from "react";
import { IconFile } from "assets/icons";
import {
  COMPLETED,
  FAILED,
  IN_PROGRESS,
  IN_PROGRESS_FETCH_INTERVAL,
  IN_QUEUE,
  PROGRESS_STOP_AT,
  SUMMARY_STATUS,
  TRANSCRIPTION_STATUS,
} from "common/constants";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "components";
import "components/sidebarFile/_sidebarFile.scss";
import { useDispatch } from "react-redux";
import { generalExtraActions } from "reduxStore/actions";
import { api } from "api";
import { utils } from "common";

function SidebarFile({currentId, id, link, status, title, timeInfo, forSummary}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [newStatus, setNewStatus] = useState(status);
  const [remainingTime, setRemainingTime] = useState(Math.round(timeInfo.remaining_time));
  const estimatedTime = Math.round(timeInfo.estimated_time);
  const isActive = id === currentId;
  const isInProgress = newStatus === IN_PROGRESS;
  const isInQueue = newStatus === IN_QUEUE;
  const timeIntervalRef = useRef(null);
  const statusIntervalRef = useRef(null);

  const fetchStatus = async () => {
    try {
      setNewStatus(
        (forSummary ? await api.summaryStatus(id) : await api.transcriptionStatus(id)).status
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = () => {
    if (!isActive) {
      navigate(link);
    }
  };

  useEffect(() => {
    if (!isInProgress) return;
    if (estimatedTime && remainingTime) {
      let newProgress = Math.round(100 - (remainingTime / estimatedTime) * 100);
      if (newProgress > PROGRESS_STOP_AT) {
        newProgress = PROGRESS_STOP_AT;
      }
      if (newProgress > progress) {
        setProgress(newProgress);
      }
      if (Math.round(estimatedTime - remainingTime) % IN_PROGRESS_FETCH_INTERVAL === 0) {
        fetchStatus();
      }
    }
  }, [remainingTime]);

  useEffect(() => {
    if (newStatus !== status) {
      forSummary
        ? dispatch(generalExtraActions.transcriptionSummaryList())
        : dispatch(generalExtraActions.audioTranscriptList());
      if (newStatus === FAILED || newStatus === COMPLETED) {
        if (isActive) {
          forSummary
            ? dispatch(generalExtraActions.transcriptionSummaryDetail(id))
            : dispatch(generalExtraActions.audioTranscriptDetail(id));
        }
        clearInterval(statusIntervalRef.current);
        clearInterval(timeIntervalRef.current);
      }
    }
  }, [newStatus]);

  useEffect(() => {
    if (isInProgress || isInQueue) {
      statusIntervalRef.current = setInterval(() => {
        fetchStatus();
      }, utils.getStatusFetchInterval(estimatedTime));
    }
    if (isInProgress) {
      timeIntervalRef.current = setInterval(() => {
        setRemainingTime((remainingTime) => Math.round(remainingTime - 2));
      }, 2000);
    }

    return () => {
      clearInterval(statusIntervalRef.current);
      clearInterval(timeIntervalRef.current);
    };
  }, []);

  return (
    <li className={`transcription-file ${isActive ? "active" : ""}`}>
      <a className="file-link" onClick={handleFileChange}>
        <div>
          <img src={IconFile} alt="file"/>
          <b>{title}</b>
        </div>
        <span>{forSummary ? SUMMARY_STATUS[newStatus] : TRANSCRIPTION_STATUS[newStatus]}</span>
      </a>
      {isInProgress && <ProgressBar progress={progress} classes={"pb-dark"}/>}
    </li>
  );
}

export default SidebarFile;
